import {AppProps} from 'next/app';
import React from "react";

function AppInit({Component, pageProps}: AppProps) {
  return <div className="app-init">
    <Component suppressHydrationWarning {...pageProps} />
  </div>
}

export default AppInit;
